import { rateSetting } from '@/api/rate-setting'
import RateCourse from '../RateCourse/RateCourse.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import { members } from '@/api/members'
import Cleave from 'vue-cleave-component'

export default {
  name: 'SidebarDetailRate',
  components: {
    RateCourse,
    ShortKey,
    AppTimeline,
    AppTimelineItem,
    Cleave,
  },
  props: {
    listTransaction: {
      type: Array,
      required: true,
    },
    currentRate: {
      type: Number,
      required: false,
    },
    listCourse: {
      type: Array,
      required: true,
    },
    listGolfClass: {
      type: Array,
      required: true,
    },
    listCompany: {
      type: Array,
      required: true,
    },
    listSource: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isMasterrateClose: {
      type: Boolean,
      required: true,
    },
    isAddtionFeeClose: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      detailRate: {
        RateGolfClassRule: [],
        Course: []
      },
      listRule: [
        {
          title: this.$t('golf_common_by_day'),
          value: 'DATE',
        },
        {
          title: this.$t('golf_common_by_month'),
          value: 'MONTH',
        },
        {
          title: this.$t('golf_common_by_year'),
          value: 'YEAR',
        },
      ],
      listCourseAdded: [],
      headerRateOverview: [
        {
          key: 'GolfClassName',
          label: this.$t('golf_ratecode_golfclass'),
        },
        {
          key: '9',
          label: `9 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
        {
          key: '18',
          label: `18 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
        {
          key: '27',
          label: `27 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
        {
          key: '36',
          label: `36 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
        {
          key: '45',
          label: `45 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
        {
          key: '54',
          label: `54 ${this.$t('golf_common_hole')}`,
          class: 'text-center width-hole',
        },
      ],
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      isLoading: false,
      tabIndex: {},
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [
        {
          ComponentId: 'add-new-rate-course-detail',
          IsActive: false,
        },
        {
          ComponentId: 'master-rate',
          IsActive: false,
        },
        {
          ComponentId: 'addition-fee',
          IsActive: false,
        }
      ],
      listHistoryRate: null,
      IsCheckShowAdd: null,
      listSexCode: [],
      currentLang: this.$i18n.locale,
      countries: [],
      listChanelCode: [],
      isUseAdvantegedRate: null,
      ListAdvantegedOptions: [],
      ListAdvantegedRule: [],
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      }
    }
  },
  created() {
    this.getConfigInfo_RCOM01()
    this.getListSexCode()
    this.getCountries_RNA01()
    this.rateId = this.currentRate
    if (this.rateId) {
      this.init_RRA01_AC1()
      this.getListHistoryRate()
    }
  },
  watch: {
    isMasterrateClose(value) {
      this.allChildComponentClosed[1].IsActive = value
    },
    isAddtionFeeClose(value) {
      this.allChildComponentClosed[2].IsActive = value
    }
  },
  methods: {
    viewDetailMasterRate() {
      this.$emit('viewDetailMasterRate', this.rateId)
    },
    viewAdditionFee(rateId, course, detail, golfClassId, rateParentId) {
      this.$emit('viewAdditionFee', rateId, course, detail, golfClassId, rateParentId)
    },
    viewDetailRate(data) {
      this.$emit('viewDetailRate', data)
    },
    async getListHistoryRate() {
      const body = {
        RateId: this.rateId 
      }
      const response =  await rateSetting.getHistoryRate_RRA07(body)
      if(response.Status == 200) {
        this.listHistoryRate = response.Data.Transaction4Rate
      } else {
        this.showResToast(response)
      }
    },
    async init_RRA01_AC1() {
      // Xem chi tiết của rate
      this.isLoading = true
      const body = {
        Rate: {
          Id: this.rateId,
        },
      }
      await rateSetting
        .getDetailRate(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailRate = res.Data.Rate
            if(this.detailRate.RateGolferNumberRule.length == 0) {
              this.detailRate.RateGolferNumberRule.push({
                ApplyType:  null,
                NumberOfGolfer: null
              })
            }

            this.viewDetailRate(this.detailRate)
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })

      // Danh sách các sân đã được add vào
      this.listCourseAdded = this.detailRate.Course.map(element => element.CourseId)
    },
    async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.IsCheckShowAdd = response?.Data.find(x => x.KeyCode === 'BOOKING_GET_ADDITIONAL_FEE_FROM_RATE').KeyValue
        }
      })
      await booking.getConfigInfo({ KeyGroup: 'RATE_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.listChanelCode = JSON.parse(response?.Data.find(x => x.KeyCode === 'RATE_CHANNEL_CODE').KeyValue)
          this.isUseAdvantegedRate = response?.Data.find(x => x.KeyCode === 'RATE_USING_ADVANTEGED_RATE').KeyValue
          this.ListAdvantegedOptions = response?.Data.find(x => x.KeyCode === 'RATE_ADVANTEGED_OPTIONS') ? JSON.parse(response?.Data.find(x => x.KeyCode === 'RATE_ADVANTEGED_OPTIONS').KeyValue) : []
          this.ListAdvantegedRule = response?.Data.find(x => x.KeyCode === 'RATE_ADVANTEGED_RULE') ? JSON.parse(response?.Data.find(x => x.KeyCode === 'RATE_ADVANTEGED_RULE').KeyValue) : []
          this.ListAdvantegedRule.forEach(x => {
            x.Label = this.$t(x.Label)
          })
        }
      })
    },
    async deleteRateCourse(RateCourseId) {
      // Xoá giá sân trong rate
      const body = {
        RateCourse: {
          Id: RateCourseId,
        },
      }
      await rateSetting
        .deleteRateCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.init_RRA01_AC1()
            this.getListHistoryRate()
          }
        })
    },
    afterUpdateRateCourse(res) {
      this.init_RRA01_AC1()
      this.getListHistoryRate()
      this.$emit('afterUpdateRateCourse', res)
    },
    async deleteRateAdditionFee_DRA01_AC14(Id) {
      const body = {
        RateAdditionalDetail: {
          Id,
        },
      }
      await rateSetting
        .deleteRateAdditonalFee(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.init_RRA01_AC1()
            this.getListHistoryRate()
          }
        })
    },
    onTriggeredEventHandler(payload) {
      // if (payload.evt.ctrlKey && payload.key === S) {
      //   console.log('contentdeailRate')
      //   console.log(this.isAddtionFeeClose)
      // } 
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    viewDetailHistoryRate(item) {
      if(item.TransactionCode === "URA04" || item.TransactionCode === "IRA02") {
        this.$refs.rateCourse.openHistory(item, this.detailRate.RateParentId)
      } else if(item.TransactionCode === "URA03" || item.TransactionCode === "IRA01") {
        this.$emit('viewHistoryRate', item)
      }
    },
    async getListSexCode() {
      await commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
      if (res) {
          this.listSexCode = res.Data.filter(x => x.LangId == this.currentLang)
      }
      })
    },
    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries = response.Data.Country
      }
    },
    async updateRateAdvange() {
      const body = {
        Rate: {
          Id: this.detailRate.Id,
          RateParentId: this.detailRate.RateParentId,
          RateCode: this.detailRate.RateCode,

          RateName: this.detailRate.RateName,
          CurrencyCode: this.detailRate.CurrencyCode,
          Description: this.detailRate.Description,
          IsAdjusted: this.detailRate.IsAdjusted,
          IsTaxable: this.detailRate.IsTaxable,

          OpenDate: this.detailRate.OpenDate,
          ExpirationDate: this.detailRate.ExpirationDate,

          SpecialDayId: this.detailRate.SpecialDayId,
          WeeklyCode: this.detailRate.WeeklyCode,
          ClientId: this.detailRate.ClientId,
          SourceId: this.detailRate.SourceId,

          RateGolfClassRule: this.detailRate.RateGolfClassRule,
          SaleDateFrom: this.detailRate.SaleDateFrom != '' ? this.detailRate.SaleDateFrom : null ,
          SaleDateTo: this.detailRate.SaleDateTo != '' ? this.detailRate.SaleDateTo : null ,
          EarlyBirdFrom: this.detailRate.EarlyBirdFrom != '' ? this.detailRate.EarlyBirdFrom : null ,
          EarlyBirdTo: this.detailRate.EarlyBirdTo != '' ? this.detailRate.EarlyBirdTo : null ,
          LastMinuteFrom: this.detailRate.LastMinuteFrom != '' ? this.detailRate.LastMinuteFrom : null ,
          LastMinuteTo: this.detailRate.LastMinuteTo != '' ? this.detailRate.LastMinuteTo : null ,
          GroupFrom: this.detailRate.GroupFrom != '' ? this.detailRate.GroupFrom : null ,
          GroupTo: this.detailRate.GroupTo != '' ? this.detailRate.GroupTo : null ,
          SexCode: this.detailRate.SexCode,
          ChannelCode: this.detailRate.ChannelCode,
          NationalityCode: this.detailRate.NationalityCode,
          AdvOptionList: this.detailRate.AdvOptionList,
          Priority: this.detailRate.Priority ? Number(this.detailRate.Priority) : 0,
          RateGolferNumberRule: this.detailRate.RateGolferNumberRule[0].NumberOfGolfer && this.detailRate.RateGolferNumberRule[0].ApplyType ? [{ApplyType:this.detailRate.RateGolferNumberRule[0].ApplyType,NumberOfGolfer: Number(this.detailRate.RateGolferNumberRule[0].NumberOfGolfer)  }] : [] ,
          TeeTimeFrom: this.detailRate.TeeTimeFrom,
          TeeTimeTo: this.detailRate.TeeTimeTo
        },
      }
      await rateSetting
        .updateMasterRate(body).then(res => {
        this.showResToast(res)
          if (res.Status === '200') {
            this.init_RRA01_AC1()
            this.getListHistoryRate()
          }
        })
    }
  },
}
