import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import GolfService from '@/views/modules/ultils/GolfService/GolfService.vue'
import SpecialDay from '@/views/modules/ultils/SpecialDay/SpecialDay.vue'

import { createPopper } from '@popperjs/core'

import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { rateSetting } from '@/api/rate-setting'

import Cleave from 'vue-cleave-component'
import { commonServices } from '@/api/common-services'

const stringMath = require('string-math')

export default {
  name: 'AdditionFee',
  components: {
    SpecialDay,
    GolfService,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ShortKey
  },
  props: {
    listCourse: {
      type: Array,
      required: true,
    },
    listGolfClass: {
      type: Array,
      required: true,
    },
    listTransaction: {
      type: Array,
      required: true,
    },
    InActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      inputMaskCurrency: this.inputMaskByCurrency(),
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      },
      isOpen: false,
      addNewRateEffectiveTimeOptions: [
        {
          title: this.$t('golf_common_date_range'),
          value: 1,
        },
        {
          title: this.$t('golf_common_optional'),
          value: 0,
        },
      ],
      detailAdditionFee: {},
      courseApply: {},
      listDistributionType: [
        {
          text: this.$t('golf_common_percent'),
          value: 'PERCENT',
        },
        {
          text: this.$t('golf_common_monney'),
          value: 'FIX',
        },
      ],
      rateId: null,
      rateParentId: null,
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      listDistribution: [],
      isAddNew: true,
      isValidDistribution: true,
      isLoading: false,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [],
      listAdditionalType: []
    }
  },
  created() {
    this.getConfig_RCOM01()
  },
  watch: {
    isOpen(value) {
      this.$emit('is-open', { ComponentId: 'addition-fee', IsActive: value })
    }
  },
  computed: {
    listNumberOfHoles() {
      const listNumebrOfHole = []
      for(let i = 0; i < 54; i++) {
        listNumebrOfHole.push({
          value: i + 1,
          title: `${i + 1} hole`,
        })
      }
      return listNumebrOfHole
    }
  },
  methods: {
    open(rateId, course, detail, golfClassId, rateParentId) {
      // Reset khi bấm lần 2
      this.detailAdditionFee = {}
      this.isAddNew = true
      this.listDistribution = []

      this.isOpen = true
      if (detail) {
        this.isAddNew = false
        this.detailAdditionFee = JSON.parse(JSON.stringify(detail))
        console.log( this.detailAdditionFee)
      } else {
        this.detailAdditionFee = {
          RateAdditionalDistribution: [],
          DistributionType: 'PERCENT',
          FinalPrice: null,
          Formula: '*1',
          Price: null,
          NumberOfHole: [],
          StartTime: '00:00',
          EndTime: '23:59',
          WeeklyCode: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
          RateAdditionalType: null,
          CourseId: []
        }
      }

      this.detailAdditionFee.GolfClassId = golfClassId
      this.rateId = rateId
      this.rateParentId = rateParentId
    },
    afterUpdateAdditionFee(res) {
      if (res.Status == '200') {
        this.$emit('afterUpdateAdditionFee', res)
      }
    },
    addNewAdditionFee() {
      this.$refs.additionFeeForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          // const body = {
          //   Rate: {
          //     Id: this.rateId,
          //     RateAdditional: {
          //       GolfClassId: this.detailAdditionFee.GolfClassId,
          //       RateAdditionalDetail: {
          //         ServiceId: this.detailAdditionFee.ServiceId,
          //         NumberOfHole: this.detailAdditionFee.NumberOfHole,
          //         StartTime: this.detailAdditionFee.StartTime,
          //         EndTime: this.detailAdditionFee.EndTime,
          //         SpecialDayId: this.detailAdditionFee.SpecialDayId,
          //         WeeklyCode: this.detailAdditionFee.WeeklyCode,
          //         Price: parseInt(this.detailAdditionFee.Price),
          //         Formula: this.detailAdditionFee.Formula,
          //         FinalPrice: parseInt(this.detailAdditionFee.FinalPrice),
          //         TransactionCode: this.detailAdditionFee.TransactionCode,
          //         DistributionType: this.detailAdditionFee.DistributionType,
          //         RateAdditionalDistribution: [],
          //       },
          //     },
          //   },
          // }

          const body = {
            RateAdditional: {
              GolfClassId: this.detailAdditionFee.GolfClassId,
              RateId: this.rateId,
              RateAdditionalType: this.detailAdditionFee.RateAdditionalType,
              CourseId: this.detailAdditionFee.CourseId,
              ServiceId: this.detailAdditionFee.ServiceId,
              NumberOfHole: this.detailAdditionFee.NumberOfHole,
              StartTime: this.detailAdditionFee.StartTime,
              EndTime: this.detailAdditionFee.EndTime,
              SpecialDayId: this.detailAdditionFee.SpecialDayId,
              WeeklyCode: this.detailAdditionFee.WeeklyCode,
              Price: parseInt(this.detailAdditionFee.Price),
              Formula: this.detailAdditionFee.Formula,
              FinalPrice: parseInt(this.detailAdditionFee.FinalPrice),
              TransactionCode: this.detailAdditionFee.TransactionCode,
              DistributionType: "PERCENT"
            }
          }
          // this.listDistribution.forEach(x => {
          //   body.Rate.RateAdditional.RateAdditionalDetail.RateAdditionalDistribution.push(
          //     {
          //       CourseId: x.courseId,
          //       Amount: parseInt(x.amount),
          //     },
          //   )
          // })

          rateSetting
            .addNewRateAdditonalFee(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterUpdateAdditionFee(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateAdditionFee() {
      this.$refs.additionFeeForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            RateAdditional: {
              Id: this.detailAdditionFee.Id,
              GolfClassId: this.detailAdditionFee.GolfClassId,
              RateId: this.rateId,
              RateAdditionalType: this.detailAdditionFee.RateAdditionalType,
              CourseId: this.detailAdditionFee.CourseId,
              ServiceId: this.detailAdditionFee.ServiceId,
              NumberOfHole: this.detailAdditionFee.NumberOfHole,
              StartTime: this.detailAdditionFee.StartTime,
              EndTime: this.detailAdditionFee.EndTime,
              SpecialDayId: this.detailAdditionFee.SpecialDayId,
              WeeklyCode: this.detailAdditionFee.WeeklyCode,
              Price: parseInt(this.detailAdditionFee.Price),
              Formula: this.detailAdditionFee.Formula,
              FinalPrice: parseInt(this.detailAdditionFee.FinalPrice),
              TransactionCode: this.detailAdditionFee.TransactionCode,
              DistributionType: "PERCENT"
            }
          }
          // const body = {
          //   Rate: {
          //     Id: this.rateId,
          //     RateParentId: this.RateParentId,
          //     RateAdditional: {
          //       Id: this.detailAdditionFee.RateAdditionalId,
          //       GolfClassId: this.detailAdditionFee.GolfClassId,
          //       RateAdditionalDetail: {
          //         Id: this.detailAdditionFee.Id,
          //         ServiceId: this.detailAdditionFee.ServiceId,
          //         NumberOfHole: this.detailAdditionFee.NumberOfHole,
          //         StartTime: this.detailAdditionFee.StartTime,
          //         EndTime: this.detailAdditionFee.EndTime,
          //         SpecialDayId: this.detailAdditionFee.SpecialDayId,
          //         WeeklyCode: this.detailAdditionFee.WeeklyCode,
          //         Price: parseInt(this.detailAdditionFee.Price),
          //         Formula: this.detailAdditionFee.Formula,
          //         FinalPrice: parseInt(this.detailAdditionFee.FinalPrice),
          //         TransactionCode: this.detailAdditionFee.TransactionCode,
          //         DistributionType: this.detailAdditionFee.DistributionType,
          //         RateAdditionalDistribution: [],
          //       },
          //     },
          //   },
          // }

          // this.listDistribution.forEach(x => {
          //   body.Rate.RateAdditional.RateAdditionalDetail.RateAdditionalDistribution.push(
          //     {
          //       CourseId: x.courseId,
          //       Amount: parseInt(x.amount),
          //     },
          //   )
          // })

          rateSetting
            .updateRateAdditonalFee(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == '200') {
                this.isOpen = false
                this.afterUpdateAdditionFee(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateFinalPrice(isRateChild) {
      // if (!isRateChild) {
      //   this.detailAdditionFee.Price = parseFloat(this.detailAdditionFee.Price)
      //   this.detailAdditionFee.FinalPrice = parseFloat(this.detailAdditionFee.Price)
      // } else {
        if(this.detailAdditionFee.Formula) {
          if(Number(this.detailAdditionFee.Formula.trim().substr(0, 1))) {
            this.detailAdditionFee.FinalPrice = Number(this.detailAdditionFee.Formula)
          } else {
            if(this.detailAdditionFee.Formula.trim().length > 1) {
              this.detailAdditionFee.FinalPrice = stringMath(this.detailAdditionFee.Price + this.detailAdditionFee.Formula)
            }
          }
        }
        
      // }
    },
    selectSevice(service) {
      // Set giá mặc định của dịch vụ
      this.detailAdditionFee.Price = service.ProductPrice
      // Set transaction code mặc định khi chọn dịch vụ
      this.detailAdditionFee.TransactionCode = service.TransactionCode.TransactionCode
    },
    async getConfig_RCOM01() {
      this.listAdditionalType = []
      const body = {
        KeyGroup: 'RATE_CONFIG',
      }
      await commonServices
        .getCommon(body)
        .then(res => {
          const tmp = JSON.parse(res?.Data.find(x => x.KeyCode === 'RATE_ADDITIONAL_TYPE').KeyValue)
          tmp.forEach(x => {
            this.listAdditionalType.push({
              Code: x, 
              Name: this.$t(x.toLocaleLowerCase())
            })
          })
        })
    },
    validateDistribution() {
      let isValid = false
      let total = 0

      this.listDistribution.forEach(x => {
        total += parseInt(x.amount)
      })

      if (this.detailAdditionFee.DistributionType == 'PERCENT') {
        if (total == 100) {
          isValid = true
        }
      } else if (total == this.detailAdditionFee.FinalPrice) {
        isValid = true
      }
      this.isValidDistribution = isValid
      return isValid
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = '400px'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.isAddNew) {
          this.updateAdditionFee()
        } else {
          this.addNewAdditionFee()
        }
      } 
    },
  },
}
