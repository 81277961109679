/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_RATE_LIST,
  GET_RATE_PARENT,
  ADD_NEW_MASTER_RATE,
  UPDATE_MASTER_RATE,
  ADD_NEW_RATE_COURSE,
  GET_DETAIL_RATE,
  GET_DETAIL_RATE_COURSE,
  UPDATE_RATE_COURSE,
  DELETE_RATE_COURSE,
  DISABLE_RATE,
  ENABLE_RATE,
  DELETE_RATE,
  ADD_NEW_RATE_ADDITIONAL_FEE,
  UPDATE_RATE_ADDITIONAL_FEE,
  DELETE_RATE_ADDITONAL_FEE,
  API_RRA07,
  COPY_RATE_IRA04

} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function copyRate_IRA04(data) {
  return fetch(ROOT + COPY_RATE_IRA04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getRateList(data) {
  return fetch(ROOT + GET_RATE_LIST, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}


function getHistoryRate_RRA07(data) {
  return fetch(ROOT + API_RRA07, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getRateParent(data) {
  return fetch(ROOT + GET_RATE_PARENT, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function andNewMasterRate(data) {
  return fetch(ROOT + ADD_NEW_MASTER_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateMasterRate(data) {
  return fetch(ROOT + UPDATE_MASTER_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function andNewRateCourse(data) {
  return fetch(ROOT + ADD_NEW_RATE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailRate(data) {
  return fetch(ROOT + GET_DETAIL_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailRateCourse(data) {
  return fetch(ROOT + GET_DETAIL_RATE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateRateCourse(data) {
  return fetch(ROOT + UPDATE_RATE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteRateCourse(data) {
  return fetch(ROOT + DELETE_RATE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function disableRate(data) {
  return fetch(ROOT + DISABLE_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function enableRate(data) {
  return fetch(ROOT + ENABLE_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteRate(data) {
  return fetch(ROOT + DELETE_RATE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewRateAdditonalFee(data) {
  return fetch(ROOT + ADD_NEW_RATE_ADDITIONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateRateAdditonalFee(data) {
  return fetch(ROOT + UPDATE_RATE_ADDITIONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteRateAdditonalFee(data) {
  return fetch(ROOT + DELETE_RATE_ADDITONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const rateSetting = {
  getRateList,
  getRateParent,
  andNewMasterRate,
  updateMasterRate,
  andNewRateCourse,
  getDetailRate,
  getDetailRateCourse,
  updateRateCourse,
  deleteRateCourse,
  disableRate,
  enableRate,
  deleteRate,
  addNewRateAdditonalFee,
  updateRateAdditonalFee,
  deleteRateAdditonalFee,
  getHistoryRate_RRA07,
  copyRate_IRA04
}
