import { commonServices } from '@/api/common-services'
import 'devextreme/dist/css/dx.light.css'
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  } from 'devextreme-vue/data-grid'  
  const dataGridRef = 'dataGrid'
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  },
  props: {
    listSource: {
      type: Array,
    },
    listSourceCurent: {
      type: Array,
    },
  },
  data() {
    return {
        dataGridRef,
        idSelfModal: 'modal-select-ar',
        rowDataSelected: null,
        isBusyTable: false,
        perPage: 100,
        allMode: 'allPages',
        checkBoxesMode: 'onClick', // 'always' :
        selectedRowsData: [],
    }
  },
  computed: {
    dataGrid() {
        return this.$refs[dataGridRef].instance
    },
    selectedRowKeys() {
      return this.selectedRowsData.map((x) => x.Id);
    },
    configTable() {
        return [
            {
                caption: this.$t('golf_package_master_source'),
                field: 'SourceName',
                isShow: true,
                alignment: 'center',
                 width: '100%',
            }
          ]
    }
    
  },
  methods: {
    async openForm() {
        const list = []
        this.listSourceCurent.forEach(x => {
          list.push(this.listSource.find(y => y.Id === x))
        })
        this.selectedRowsData = list
    },
    getSelectedData() {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData()
      },
    resetForm() {
      this.selectedRowsData = []
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'select-Source')
    },
    selectSource() {
      if(this.selectedRowsData.lenngth === 0) {
        this.$emit('event', {type: 'after-select-source', data: []})
      } else {
        this.$emit('event', {type: 'after-select-source', data: JSON.parse(JSON.stringify(this.selectedRowsData))})
      }
        this.$root.$emit('bv::hide::modal', 'select-Source')
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
  },
}
