/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_GOLF_CLASS,
  ADD_NEW_GOLF_CLASS,
  UPDATE_GOLF_CLASS,
  ACTIVE_GOLF_CLASS,
  DEACTIVE_GOLF_CLASS,
  DELETE_GOLF_CLASS
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListGolfClass(data) {
  return fetch(ROOT + GET_LIST_GOLF_CLASS, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewGolfClass(data) {
  return fetch(ROOT + ADD_NEW_GOLF_CLASS, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function updateGolfClass(data) {
  return fetch(ROOT + UPDATE_GOLF_CLASS, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function changeStatusGolfClass(status, data) {
  const key = status ? ACTIVE_GOLF_CLASS : DEACTIVE_GOLF_CLASS
  return fetch(ROOT + key, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function deleteGolfClass(data) {
  return fetch(ROOT + DELETE_GOLF_CLASS, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const golfClass = {
  getListGolfClass,
  addNewGolfClass,
  updateGolfClass,
  changeStatusGolfClass,
  deleteGolfClass
}
