import {
  BFormInput, BRow, BCol,
  BFormGroup, BButton, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  name: 'SelectChecbox',
  components: {
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    BFormRadioGroup,
  },
  props: {
    value: {
      type: Array,
      required: false,
    },
    error: {
      type: Number,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value)
    },
    isDisabled(id) {
      if (!this.multiple) {
        if (this.value.length > 0 && !this.value.includes(id)) {
          return true
        }
        return false
      }
    },
  },
}
