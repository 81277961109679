import vSelect from 'vue-select'

import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import ShortKey from '@/views/modules/ultils/ShortKey'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

import { rateSetting } from '@/api/rate-setting'
import { commonServices } from '@/api/common-services'
import { courseSetting } from '@/api/course-setting'
import { golfClassSetting } from '@/api/golf-class-setting'
import MasterRate from './MasterRate/MasterRate.vue'
import AdditionFee from './AdditionFee/AdditionFee.vue'
import ContentDetailRate from './ContentDetailRate/ContentDetailRate.vue'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import '@core/scss/vue/libs/vue-select.scss'

import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'

const dataGridRef = 'tableRate'

export default {
  name: 'RateCode',
  components: {
    ContentDetailRate,
    AdditionFee,
    MasterRate,
    vSelect,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    'delete-dialog': DeleteDialog,
    'confirm-dialog': ConfirmDialog,
    ShortKey
  },
  data() {
    return {
      listCourse: [],
      listTA: [],
      listSource: [],
      listRateParent: [],
      listGolfClass: [],
      listRate: [],
      listTransaction: [],
      listNumberOfHoles: [
        {
          value: 9,
          title: '9 Hole',
        },
        {
          value: 18,
          title: '18 Hole',
        },
        {
          value: 27,
          title: '27 Hole',
        },
      ],
      configTable: [
        {
          caption: this.$t('golf_ratecode_code'),
          field: 'RateCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'rate-code',
          width: '10%',
        },
        {
          caption: this.$t('golf_ratecode_name'),
          field: 'RateName',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'rate-name',
          width: '20%',
        },
        // {
        //   caption: this.$t('golf_ratecode_description'),
        //   field: 'Description',
        //   isShow: true,
        //   alignment: 'left',
        //   width: '20%',
        //   cellTemplate: 'open-date',
        // },
        {
          caption: this.$t('golf_ratecode_opendate'),
          field: 'OpenDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'open-date',
          width: '15%',
        },
        {
          caption: this.$t('golf_ratecode_exp'),
          field: 'ExpirationDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'exp-date',
          width: '15%',
        },
        // {
        //   caption: this.$t('golf_ratecode_special'),
        //   field: 'SpecialDayId',
        //   isShow: true,
        //   alignment: 'left',
        // },
        {
          caption: this.$t('golf_ratecode_course_apply'),
          field: 'CourseId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'course-apply',
          width: '15%',
        },
        {
          caption: this.$t('golf_ratecode_golfclass'),
          field: 'GolfClassId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'golf-class',
          width: '15%',
        },
        {
          caption: this.$t('golf_ratecode_company'),
          field: 'ClientId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'company',
          width: '15%',
        },
        // {
        //   caption: this.$t('golf_ratecode_source'),
        //   field: 'SourceId',
        //   isShow: true,
        //   alignment: 'left',
        // },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'left',
        },
      ],
      headerColumns: [
        { title: this.$t('golf_ratecode_course_apply'), field: 'CourseId' },
        { title: this.$t('golf_ratecode_description'), field: 'Description' },
        { title: this.$t('golf_ratecode_exp'), field: 'ExpirationDate' },
        { title: this.$t('golf_ratecode_golfclass'), field: 'GolfClassId' },
        { title: this.$t('golf_ratecode_opendate'), field: 'OpenDate' },
        { title: this.$t('golf_ratecode_code'), field: 'RateCode' },
        { title: this.$t('golf_ratecode_name'), field: 'RateName' },
        { title: this.$t('golf_ratecode_source'), field: 'SourceId' },
        { title: this.$t('golf_ratecode_special'), field: 'SpecialDayId' },
        { title: this.$t('golf_ratecode_company'), field: 'TAId' },
      ],
      dataGridRef: 'tableRate',
      listCurrency: JSON.parse(localStorage.getItem('userData')).currency,
      listCompany: [],
      search: {
        KeyWord: null,
        courseId: [],
        startDate: this.getUTC('start', 'year'), // dayjs().startOf('year').toDate(),
        endDate: this.getUTC('end', 'year'), // dayjs().endOf('year').toDate(),
      },
      currentRate: null,
      currentRateStatus: null,
      currentRateName: null,
      reload: 1,
      isOpenSidebar: false,
      isOpenModal: false,
      isLoading: false,
      isForced: false,
      perPage: 10,
      optionPerPage: [10, 50, 100],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [
        {
          ComponentId: 'master-rate',
          IsActive: false,
        },
        {
          ComponentId: 'modal-detail-rate',
          IsActive: false,
        },
        {
          ComponentId: 'sidebar-detail-rate',
          IsActive: false,
        },
        {
          ComponentId: 'addition-fee',
          IsActive: false,
        }
      ],
      windowHeight: 0,
      heightTable: 400
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    this.init_RRA01_AC1()
  },
  watch: {
    isOpenModal(value) {
      this.SidebarOpenOrCloseHandler({ ComponentId: 'modal-detail-rate', IsActive: value })
    },
    isOpenSidebar(value) {
      this.SidebarOpenOrCloseHandler({ ComponentId: 'sidebar-detail-rate', IsActive: value })
    },
    windowHeight(value) {
      this.heightTable = value - 290
    }
  },
  mounted() {
    this.windowHeight = window.innerHeight
  },
  methods: {
    disableRate_URA01_AC10_dialog(data) {
      this.disableRate_URA01_AC10(data.Id)
    },
    enableRate_URA01_AC11_dialog(data) {
      this.enableRate_URA01_AC11(data.Id)
    },
    deleteRate_DRA01_AC12_dialog(data) {
      this.deleteRate_DRA01_AC12(data.Id)
    },
    async disableRate_URA01_AC10(rateId) {
      const body = {
        Rate: {
          Id: rateId,
        },
      }
      await rateSetting
        .disableRate(body)
        .then(res => {
          if (res.Status === '200' && res.Data.Message) {
            this.$refs.disableDialogComponentForce.open({
              Id: rateId,
              dataConfirm: {
                title: this.$t('golf_common_deactive'),
                content: res.Data.Message,
              },
            })
          } else if (res.Status === '200' && !res.Data.Message) {
            this.showResToast(res)
            // Load lại thông tin detail
            this.getListRateCode_RRA01_AC2()
            if(this.$refs.contentDetailRate) {
              this.$refs.contentDetailRate.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
            if(this.$refs.contentDetailRateModal) {
              this.$refs.contentDetailRateModal.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
            this.currentRateStatus = true
          }
        })
    },
    async disableRate_URA01_AC10_force(rateId) {
      const body = {
        Rate: {
          Id: rateId.Id,
        },
        IsForce: true,
      }
      await rateSetting
        .disableRate(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            // Load lại thông tin detail
            this.getListRateCode_RRA01_AC2()
            if(this.$refs.contentDetailRate) {
              this.$refs.contentDetailRate.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
            if(this.$refs.contentDetailRateModal) {
              this.$refs.contentDetailRateModal.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
            this.currentRateStatus = true
          }
        })
    },
    async enableRate_URA01_AC11(rateId) {
      const body = {
        Rate: {
          Id: rateId,
        },
      }
      await rateSetting
        .enableRate(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.currentRateStatus = false
            this.getListRateCode_RRA01_AC2()
            if(this.$refs.contentDetailRate) {
              this.$refs.contentDetailRate.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
            if(this.$refs.contentDetailRateModal) {
              this.$refs.contentDetailRateModal.init_RRA01_AC1()
              this.$refs.contentDetailRate.getListHistoryRate()
            }
          }
        })
    },
    async deleteRate_DRA01_AC12(rateId) {
      const body = {
        Rate: {
          Id: rateId,
        },
      }
      await rateSetting
        .deleteRate(body)
        .then(res => {
          this.showResToast(res)
          this.isOpenSidebar = false
          this.isOpenModal = false
          if (res.Status === '200') {
            this.getListRateCode_RRA01_AC2()
          }
        })
    },
    viewDetailMasterRate(rateId) {
      this.$refs.masterRate.open(rateId)
    },
    viewHistoryRate(item) {
      this.$refs.masterRate.openHistory(JSON.parse(item.NewValue))

    },
    viewAdditionFee(rateId, course, detail, golfClassId, rateParentId) {
      this.$refs.additionFee.open(rateId, course, detail, golfClassId, rateParentId)
    },
    viewDetailRate(item) {
      this.forceReload()
      if (item.column.name != 'action' && item.data.Id) {
        this.isOpenSidebar = true
        this.currentRate = item.data.Id
        this.currentRateStatus = item.data.InActive
        this.currentRateName = item.data.RateName
      }
    },
    afterUpdateMasterRate() {
      // Load lại thông tin list rate
      this.getListRateCode_RRA01_AC2()
      // Load lại thông tin detail
      if(this.$refs.contentDetailRate) {
        this.$refs.contentDetailRate.init_RRA01_AC1()
        this.$refs.contentDetailRate.getListHistoryRate()
      }
      if(this.$refs.contentDetailRateModal) {
        this.$refs.contentDetailRateModal.init_RRA01_AC1()
        this.$refs.contentDetailRateModal.getListHistoryRate()
      }
    },
    afterAddNewMasterRate(res) {
      // Load lại thông tin list rate
      this.getListRateCode_RRA01_AC2()
      this.currentRate = res.Data.Rate.Id
      // Mở popup detail package để thêm golf fee
      this.isOpenModal = true
    },
    afterUpdateAdditionFee(res) {
      this.$refs.sidebarDetailRate.afterUpdateRateCourse()
    },
    inActiveRow(e) {
      if (e.rowType !== 'data') { return }
      if (e.data.InActive) {
        e.rowElement.style.fontStyle = 'italic'
        e.rowElement.style.backgroundColor = 'rgba(193, 193, 193, 0.6)'
      }
    },
    async init_RRA01_AC1() {
      this.isLoading = true
      // Lấy list sân
      await courseSetting
        .getListCourse()
        .then(res => {
          if (res.Status === '200') {
            this.listCourse = res.Data.Course
            // this.search.courseId = this.listCourse.map(x => x.Id)
          } else {
            this.showResToast(res)
          }
        })

      // Lấy list rate
      await this.getListRateCode_RRA01_AC2()

      // Lấy danh sách rate cha
      await rateSetting
        .getRateParent()
        .then(res => {
          if (res.Status === '200') {
            this.listRateParent = res.Data.Rate
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách golf class
      const body = {
        KeyWord: null,
        CourseId: [],
      }
      await golfClassSetting
        .getListGolfClass(body)
        .then(res => {
          if (res.Status === '200') {
            this.listGolfClass = res.Data.GolfClass
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách company
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listCompany = res.Data.Client
          } else {
            this.showResToast(res)
          }
        })

      // // Lấy danh sách đơn vị tiền tệ
      // await commonServices
      //   .getCurrency()
      //   .then(res => {
      //     if (res.Status === '200') {
      //       this.listCurrency = res.Data.CurrencyCode
      //     }
      //   })

      // Lấy danh sách source
      await commonServices
        .getListSource()
        .then(res => {
          if (res.Status === '200') {
            this.listSource = res.Data.Source
          } else {
            this.showResToast(res)
          }
        })

      const bodyTrans = {
        hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      }
      // Lấy danh sách transaction
      await commonServices
        .getListTransactionCode(bodyTrans)
        .then(res => {
          if (res.Status === '200') {
            this.listTransaction = res.Data.TransactionCode
            this.listTransaction.forEach(x => {
              x.DescriptionDetail = x.TransactionCode + ' - ' + x.Description
            });
          } else {
            this.showResToast(res)
          }
        })

      this.isLoading = false
    },
    displayGolfClassCode(GolfClassId) {
      const response = this.listGolfClass.filter(x => x.Id === GolfClassId)
      if (response.length) {
        return response[0]
      }

      return {}
    },
    async getListRateCode_RRA01_AC2() {
      const body = {
        RateCode: this.search.keyWord,
        OpenDate: this.search.startDate,
        ExpirationDate: this.search.endDate,
        CourseId: this.search.courseId,
      }
      await rateSetting
        .getRateList(body)
        .then(res => {
          if (res.Status === '200') {
            this.listRate = res.Data.Rate.filter(x => !x.IsDeleted)
          } else {
            this.showResToast(res)
          }
        })
    },
    exportPDF() {
      const pdfDoc = new jsPDF('p', 'pt', 'a4')
      exportDataGridToPdf({
        jsPDFDocument: pdfDoc,
        component: this.dataGrid,
      }).then(() => {
        pdfDoc.setFontSize(12)
        const pageCount = pdfDoc.internal.getNumberOfPages()
        for (let i = 1; i <= pageCount; i++) {
          pdfDoc.setPage(i)
          const { pageSize } = pdfDoc.internal
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          const header = 'Report 2021'
          const footer = `Page ${i} of ${pageCount}`
          const logo = 'iVBORw0KGgoAAAANSUhEUgAAAHUAAAAcCAMAAABoIQAcAAAAw1BMVEUAAAAQSqEQR54QSZ8QSZ8Aod0AoNwAn9sRR50RRZr/7Un/808RS58TRpr/6UMMX64RS6AbM4H+yCEcMoKJglj/zSj7xyQRSp8AoNwRR5wRSJ0RSZ4VQZMUQpYSRpoTRJj/6UP/8EsWPpAAmNgXOosaNIQTRJf/8k3/5T8Andr+3jkAk9T/7kj/4jz+2jQAjtL/7Eb+1zABic4Hdr/+0y3+0CoDhMoEfcVQYnb+zCXHv1bFuVAJZrRUYXOLjGHErkfEqUEir4bvAAAAF3RSTlMAf0D+v3+/QP7+QL+/v3/+v7+/f39/QKzJvtMAAAJISURBVEjH7ZXpctowFIUtWTZLm3TvxRbGAQJJmjS00LAk3d7/qXoXScjTYQI/yo8mZ7B0pdH4m3NkieRZT0hatUsL8FIlx5M+KYqytDYFAJ0cSbogaMlQ9JscRSedAWFtCYJ9nRxBespU8poC/cAk/1y6mnYKVBv3FUhIPgirldIHL6iqeiAJI5WQ+Oz1SSkFRinsAVSyW9GC00+3X7/dffiIUxE1BS/zONOv3Jvavbz8zNjN2/f9alr7fQWUI6vHYmUiLdyX2j07Qypi7zabdZ+xRbuNR4eQ/kkayrJWc8LnoaMAlXJ03kaNA+40L+jefEGsmP2N0Kqu/cfkI361WGQRIu+hcuRixyVbbVrhKamwC7PGR3c6vCEqY9d9xNYccEg4Xbh3B6NhHKjBX6AKlMqYasLscOjN3v4ajYjaGXDCVrKd97xcqlxlbtjiUUwN79eMjqg0Nq68YCx/UGukVtWUzJbtUhJeXvWCPDVnwxmnzf3fVIncNKjceCph2ezP8xEnzEcHzaY2heX4aovNo4CFmtPkbqraSX2BWM744Ryp5NWd1xTNrq7H48hsSzLNM5LL15HgMGr3QjJ+mCA1JFyQVVhdM7VpVrrtppL8X6JuUmEnFc0S9n6CVEo4UK1dzZpUYeRsNs9dhRKzRim+m6iS1mDDVKWoiUvCDu9/TCIq3/7WLuazGVEjrMQqokIUDqL3BtpPiHmkO5vRVffuDTKZili/r9+Xc6I2zWbhxGbxLYHSYpcqgw6FoLY7bZT2JWD5rP9SfwAlY2dKPlPjkQAAAABJRU5ErkJggg=='

          // Add logo
          pdfDoc.addImage(logo, 'JPEG', 40, 10, 50, 25)

          // Header
          pdfDoc.text(header, 120, 20, { baseline: 'top' })

          // Footer
          pdfDoc.text(footer, pageWidth / 2 - (pdfDoc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' })
        }
      }).then(() => {
        pdfDoc.save('Customers.pdf')
      })
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Employees')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Country Area, Population, and GDP Structure'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)

        footerRow.getCell(1).value = 'www.wikipedia.org'
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx')
        })
      })
    },
    forceReload() {
      this.reload++
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    onTriggeredEventHandler(payload) {
      // if (payload.key === F1) {
      //   console.log('ok')
      // } 
    },
    async copyRate(data, check) {
      const body = {
        RateCopyId: check ? data : data.Id
      }
      await rateSetting.copyRate_IRA04(body).then(async res => {
        this.showResToast(res)
        if(res.Status == 200 && !check) {
          await this.getListRateCode_RRA01_AC2()
          this.viewDetailRateCopy(this.listRate.find(x => x.Id == res.Data.RateId))
        } else {
          await this.getListRateCode_RRA01_AC2()
        }
      })
    },
    viewDetailRateCopy(item) {
      this.forceReload()
        this.isOpenSidebar = true
        this.currentRate = item.Id
        this.currentRateStatus = item.InActive
        this.currentRateName = item.RateName
    }
  },
}
