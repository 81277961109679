import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { rateSetting } from '@/api/rate-setting'
import { golfClass } from '@/api/golf-class'
import SpecialDay from '@/views/modules/ultils/SpecialDay/SpecialDay.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import dayjs from 'dayjs'
import selectClient from '@/views/modules/rate-code/selectClient/selectClient.vue'
import selectSource from '@/views/modules/rate-code/selectSource/selectSource.vue'

export default {
  name: 'MasterRate',
  components: {
    SpecialDay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DatePicker,
    ShortKey,
    selectClient,
    selectSource
  },
  props: {
    listRateParent: {
      type: Array,
      required: true,
    },
    listCurrency: {
      type: Array,
      required: true,
    },
    listCompany: {
      type: Array,
      required: true,
    },
    listSource: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      isOpen: false,
      rangeDateTimeApply: {
        startDate: this.getUTC('start', 'day'),
        endDate: this.getUTC('start', 'day'),
      },
      addNewRateEffectiveTimeOptions: [
        {
          title: this.$t('golf_common_date_range'),
          value: 1,
        },
        {
          title: this.$t('golf_common_optional'),
          value: 0,
        },
      ],
      addNewRateEffectiveTime: 1,
      detailMasterRate: {
        OpenDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('start', 'day'),
        RateGolfClassRule: [
          {
            ApplyType: null,
            NumberOfUse: null,
            GolfClassId: null,
          }
        ],
        SpecialDayId: null,
        ClientId: [],
        SourceId: [],
      },
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      isLoading: false,
      listRule: [
        {
          title: this.$t('golf_common_by_day'),
          value: 'DATE',
        },
        {
          title: this.$t('golf_common_by_month'),
          value: 'MONTH',
        },
        {
          title: this.$t('golf_common_by_year'),
          value: 'YEAR',
        },
      ],
      listGolfClass: [],
      ranges: this.templateRange(),
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [],
      isViewHistory: false
    }
  },
  watch: {
    isOpen(value) {
      if(!value) {
        this.isViewHistory = false
      }
      this.$emit('is-open', { ComponentId: 'master-rate', IsActive: value })
    }
  },
  methods: {
    _dayjs: dayjs,
    hideModal() {
      this.isOpen = false
    },
    open(rateId, rateParentId) {
      this.isOpen = true
      this.rateId = rateId
      if (this.rateId) {
        this.init_RRA01_AC1()
      } else {
        this.rangeDateTimeApply = {
          startDate: this.getUTC('start', 'day'),
          endDate: this.getUTC('start', 'day'),
        }

        this.detailMasterRate = {
          IsTaxable: true,
          OpenDate: this.getUTC('start', 'day'),
          ExpirationDate: this.getUTC('end', 'day'),
          RateGolfClassRule: [
            {
              ApplyType: null,
              NumberOfUse: null,
              GolfClassId: null,
            }
          ],
          ClientId: [],
          SourceId: [],
          SpecialDayId: null
        }
        this.detailMasterRate.RateParentId = rateParentId
      }
      this.getApiListGolfClass()
    },
    openHistory(item) {
      this.getApiListGolfClass()
      if(item.Rate.RateGolfClassRule.length == 0) {
        item.Rate.RateGolfClassRule = [
          {
            ApplyType: null,
            NumberOfUse: null,
            GolfClassId: null,
          }
        ]
      }
      this.detailMasterRate = item.Rate
      if (this.detailMasterRate.SpecialDayId) {
        this.addNewRateEffectiveTime = 0
      } else {
        this.addNewRateEffectiveTime = 1
      }
      if(this.detailMasterRate.ClientName) {
        this.detailMasterRate.ClientName = this.detailMasterRate.ClientName.join()
      }
      if(this.detailMasterRate.SourceName) {
        this.detailMasterRate.SourceName = this.detailMasterRate.SourceName.join()
      }

      this.isOpen = true
      this.isViewHistory = true
    },
    convertToUTC0(date) {
      const timeZone = localStorage.getItem('timeZone')
      const offset = this.dayjs().utc().tz(timeZone).startOf('day')
        .utcOffset()
      return this.dayjs(date)
        .add(-offset, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    },
    afterUpdateMasterRate(res) {
      if (res.Status == '200') {
        this.$emit('afterUpdateMasterRate', res)
      }
    },
    afterAddNewMasterRate(res) {
      if (res.Status == '200') {
        this.$emit('afterAddNewMasterRate', res)
      }
    },
    async init_RRA01_AC1() {
      this.isLoading = true
      // Chi tiết Master Rate
      const body = {
        Rate: {
          Id: this.rateId,
        },
      }
      await rateSetting
        .getDetailRate(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailMasterRate = res.Data.Rate

            if (this.detailMasterRate.SpecialDayId) {
              this.addNewRateEffectiveTime = 0
            } else {
              this.addNewRateEffectiveTime = 1
            }
            this.changeRateEffectiveTime()
            if (this.detailMasterRate.RateGolfClassRule.length == 0) {
              this.detailMasterRate.RateGolfClassRule.push({
                ApplyType: null,
                NumberOfUse: null,
                GolfClassId: null,
              })
            }
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    addNewMasterRate_IRA01_AC4() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.detailMasterRate.RateGolfClassRule[0].NumberOfUse = +this.detailMasterRate.RateGolfClassRule[0].NumberOfUse
          const body = {
            Rate: {
              RateParentId: this.detailMasterRate.RateParentId,
              RateCode: this.detailMasterRate.RateCode,

              RateName: this.detailMasterRate.RateName,
              CurrencyCode: this.detailMasterRate.CurrencyCode,
              Description: this.detailMasterRate.Description,
              IsAdjusted: this.detailMasterRate.IsAdjusted,
              IsTaxable: this.detailMasterRate.IsTaxable,

              OpenDate: this.detailMasterRate.OpenDate,
              ExpirationDate: this.detailMasterRate.ExpirationDate,

              SpecialDayId: this.detailMasterRate.SpecialDayId,
              WeeklyCode: this.detailMasterRate.WeeklyCode,
              ClientId: this.detailMasterRate.ClientId,
              SourceId: this.detailMasterRate.SourceId,

              RateGolfClassRule: (this.detailMasterRate.RateGolfClassRule[0].GolfClassId) ? this.detailMasterRate.RateGolfClassRule : [],

            },
          }

          rateSetting
            .andNewMasterRate(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterAddNewMasterRate(res)
              }
            })
        }
      })
    },
    updateMasterRate_URA01_AC7() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.detailMasterRate.RateGolfClassRule[0].NumberOfUse = +this.detailMasterRate.RateGolfClassRule[0].NumberOfUse
          const body = {
            Rate: {
              Id: this.rateId,
              RateParentId: this.detailMasterRate.RateParentId,
              RateCode: this.detailMasterRate.RateCode,

              RateName: this.detailMasterRate.RateName,
              CurrencyCode: this.detailMasterRate.CurrencyCode,
              Description: this.detailMasterRate.Description,
              IsAdjusted: this.detailMasterRate.IsAdjusted,
              IsTaxable: this.detailMasterRate.IsTaxable,

              OpenDate: this.detailMasterRate.OpenDate,
              ExpirationDate: this.detailMasterRate.ExpirationDate,

              SpecialDayId: this.detailMasterRate.SpecialDayId,
              WeeklyCode: this.detailMasterRate.WeeklyCode,
              ClientId: this.detailMasterRate.ClientId,
              SourceId: this.detailMasterRate.SourceId,

              RateGolfClassRule: (this.detailMasterRate.RateGolfClassRule[0].GolfClassId) ? this.detailMasterRate.RateGolfClassRule : [],
              SaleDateFrom: this.detailMasterRate.SaleDateFrom,
              SaleDateTo: this.detailMasterRate.SaleDateTo,
              EarlyBirdFrom: this.detailMasterRate.EarlyBirdFrom,
              EarlyBirdTo: this.detailMasterRate.EarlyBirdTo,
              LastMinuteFrom: this.detailMasterRate.LastMinuteFrom,
              LastMinuteTo: this.detailMasterRate.LastMinuteTo,
              GroupFrom: this.detailMasterRate.GroupFrom,
              GroupTo: this.detailMasterRate.GroupTo,
              SexCode: this.detailMasterRate.SexCode,
              ChannelCode: this.detailMasterRate.ChannelCode,
              NationalityCode: this.detailMasterRate.NationalityCode,
              AdvOptionList: this.detailMasterRate.AdvOptionList,
              Priority: this.detailMasterRate.Priority,
              RateGolferNumberRule: this.detailMasterRate.RateGolferNumberRule,
              TeeTimeFrom: this.detailMasterRate.TeeTimeFrom,
              TeeTimeTo: this.detailMasterRate.TeeTimeTo,
            },
          }
          rateSetting
            .updateMasterRate(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == '200') {
                this.isOpen = false
                this.afterUpdateMasterRate(res)
              }
            })
        }
      })
    },
    changeRateEffectiveTime() {
      if (this.addNewRateEffectiveTime) {
        this.detailMasterRate.SpecialDayId = null
      }
    },
    templateRange() {
      return {
        golf_date_range_today: [this.dayjs().utc().format(), this.dayjs().utc().format()],
        golf_date_range_14_day_ago: [this.dayjs().utc().subtract(14, 'day').format(), this.dayjs().utc().format()],
        golf_date_range_this_weeek: [this.dayjs().utc().startOf('week').format(), this.dayjs().utc().endOf('week').format()],
        golf_date_range_this_month: [this.dayjs().utc().startOf('month').format(), this.dayjs().utc().endOf('month').format()],
        golf_date_range_last_month: [this.dayjs().utc().subtract(1, 'months').startOf('month')
          .format(), this.dayjs().utc().subtract(1, 'months').endOf('month')
          .format()],
        golf_date_range_this_quarter: [this.dayjs().utc().startOf('quarter').format(), this.dayjs().utc().endOf('quarter').format()],
        golf_date_range_last_quarter: [this.dayjs().utc().subtract(1, 'quarter').startOf('quarter')
          .format(), this.dayjs().utc().subtract(1, 'quarter').endOf('quarter')
          .format()],
      }
    },
    async getApiListGolfClass() {
      await golfClass
        .getListGolfClass({
          KeyWord: null,
          CourseId: [],
        })
        .then(res => {
          if (res) {
            this.listGolfClass = res.Data.GolfClass
          }
        })
    },
    selectAllClient() {
      this.detailMasterRate.ClientId = this.listCompany.map(x => x.Id)
    },
    deSelectAllClient() {
      this.detailMasterRate.ClientId = []
    },
    selectAllSource() {
      this.detailMasterRate.SourceId = this.listSource.map(x => x.Id)
    },
    deSelectAllSource() {
      this.detailMasterRate.SourceId = []
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(this.rateId) {
          if(!this.detailMasterRate.InActive) {
            this.updateMasterRate_URA01_AC7()
          }
        } else {
          this.addNewMasterRate_IRA01_AC4()
        }
      } 
    },
    findTextClient(data) {
      var text = ''
      data.forEach((x,index) => {
        if(this.listCompany.find(y => y.Id === x)) {
          if(index === 0 ) {
            text += this.listCompany.find(y => y.Id === x).ClientName
          } else {
            text = text +  ','+ this.listCompany.find(y => y.Id === x).ClientName
          }
        }
      });
      return text
    },
    findTextSource(data) {
      var text = ''
      data.forEach((x,index) => {
        if(this.listSource.find(y => y.Id === x)) {
          if(index === 0 ) {
            text += this.listSource.find(y => y.Id === x).SourceName
          } else {
            text = text +  ','+ this.listSource.find(y => y.Id === x).SourceName
          }
        }
      });
      return text
    },
    showPopupSelectClient() {
      this.$root.$emit('bv::show::modal', 'select-Client')
    },
    showPopupSelectSource() {
      this.$root.$emit('bv::show::modal', 'select-Source')
    },
    handEvent(result) {
      if(result.type == 'after-select-client') {
        this.detailMasterRate.ClientId = result.data.map(x => x.Id)
      }
      if(result.type == 'after-select-source') {
        this.detailMasterRate.SourceId = result.data.map(x => x.Id)
      }
    }
  },
}
